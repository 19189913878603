<template>
  <div class="main">
    <div v-if="employer" class="columns is-centered is-multiline is-mobile">
      <img class="column is-full firm-logo" :src="`https://cdn.statically.io/img/${employer.logo.replace(/(https:\/\/)|-/g, '')}`">
      <p class="column is-full is-size-2 has-text-centered firm-name">{{employer.name}}</p>
      <b-taglist v-if="employer.accredited || employer.premium || employer.underbar">
        <b-tag type="is-green" v-if="employer.accredited">Accredited</b-tag>
        <b-tag type="is-gold" v-if="employer.premium">Platinum Sponsor</b-tag>
        <b-tag v-if="employer.underbar">Underbars Accepted</b-tag>
      </b-taglist>

      <div class="buttons field is-grouped is-grouped-centered column is-full">
        <b-button v-if="employer.appform" type="is-primary" tag="a" :href="employer.appform" target="_blank">Submit your CV</b-button>
        <b-button v-if="employer.website" tag="a" :href="employer.website" target="_blank" type="is-primary" outlined>Visit Website</b-button>
        <b-button v-if="employer.avp" tag="a" :href="employer.avp" target="_blank" type="is-primary" outlined>Watch Video</b-button>
      </div>

      <div class="column is-8-tablet is-full-mobile" v-if="employer.orientation">
        <div class="orientation-box has-background-primary">
          <div class="columns is-centered is-mobile">
            <div class="column is-full">
              <figure class="image is-16by9 has-background-grey">
                <iframe :src="employer.orientation" class="has-ratio"></iframe>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div v-if="employer.images" class="column is-half-tablet is-full-mobile">
        <b-carousel :autoplay="false" :arrow-hover="false" icon-size="is-medium" :has-drag="false" indicator-background>
          <b-carousel-item v-for="(item, i) in employer.images" :key="i">
            <a class="image" :href="item" target="_blank">
              <img :src="`https://cdn.statically.io/img/${item.replace(/(https:\/\/)|-/g, '')}`">
            </a>
          </b-carousel-item>
        </b-carousel>
        <p class="is-size-7 has-text-centered has-text-grey is-italic">Click on the image to enlarge</p>
      </div>

      <div class="column is-full-mobile" v-bind:class="{'is-half-tablet': employer.images, 'is-two-thirds-tablet': !employer.images}">
        <b-tabs v-model="activeTab">
          <b-tab-item label="About" v-if="employer.writeup">
            <div class="content">
              <vue-markdown>{{employer.writeup}}</vue-markdown>
            </div>
          </b-tab-item>
          <b-tab-item :label="employer.underbar === true ? 'Internship & Underbar Details' : 'Internship Details'" v-if="employer.apply">
            <div class="content">
              <vue-markdown>{{employer.apply}}</vue-markdown>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>

    </div>
    <div v-else>
      <Loader></Loader>
    </div>
  </div>
</template>

<script>
import {supabase} from "../supabaseClient";
import VueMarkdown from '@adapttive/vue-markdown'
import Loader from "../components/Loader";

export default {
  name: "EmployerView",
  components: {Loader, VueMarkdown},
  title: "Employer Details | Adhika: Ateneo Law School Career Fair 2022",
  data() { return {
    employer: null,
    id: this.$route.params.id,
    activeTab: 0
  }},
  methods: {
    async loadEmployerData() {
      const {data, error} = await supabase
        .from('employers')
        .select()
        .eq('id',this.id)
      this.employer = data[0]
      console.log(this.employer)
      console.log(error)
    }
  },
  async mounted() {
    await this.loadEmployerData()
    document.title = `${this.employer.name} | ALS Career Fair 2022`
  }
}
</script>

<style scoped>
.main {
  padding: 6.9%;
}

.firm-logo {
  max-width: 240px;
}

.firm-name {
  font-family: "EB Garamond", serif;
  color: #0a0e14
}

.orientation-box {
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 36px;
}
</style>